/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Link } from 'react-router-dom'
import { type MenuItem } from 'types/navigation'
import NavigationMenuItem from './NavigationMenuItem'

const NavigationMenu: React.FC<MenuItem> = ({
  label,
  items,
  route,
  testid,
  properties
}) => {
  if (!items?.length) {
    return (
      <NavigationMenuItem to={route as string} testid={testid} isLink>
        {label}
      </NavigationMenuItem>
    )
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center">
        <NavigationMenuItem testid={testid}>
          <React.Fragment>
            <span>{label}</span>

            <ChevronDownIcon
              className="ml-1 -mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </React.Fragment>
        </NavigationMenuItem>
      </div>

      <Transition
        as={'div'}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute mt-4 -m${properties?.floatOn?.charAt(0)}-2 ${
            properties?.floatOn
          }-0 border border-gray-100 ${
            properties?.subMenuWidthClass
          } bg-white shadow-md rounded divide-y divide-gray-100 focus:outline-none`}
        >
          {items.map((links, idx) => (
            <div key={idx}>
              {links.map(({ route, label, name, testid }) => (
                <Menu.Item key={name}>
                  {({ active }) => (
                    <Link
                      to={route}
                      className={`${
                        active ? 'bg-gray-100' : 'text-gray-900'
                      } group flex w-full items-center px-6 py-2.5 my-1 text-sm`}
                      data-testid={testid}
                    >
                      {label}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

NavigationMenu.defaultProps = {
  properties: {
    floatOn: 'left',
    subMenuWidthClass: 'w-64 2xl:w-72'
  }
}

export default React.memo(NavigationMenu)
