import React from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from './Navigation'

const AppLayout: React.FC = () => {
  const [
    dashboardMinimumHeight,
    setDashboardMinimumHeight
  ] = React.useState<number>(window.innerHeight)

  const navigationRef = React.useRef<HTMLElement>(null)

  React.useLayoutEffect(() => {
    setDashboardMinimumHeight(
      window.innerHeight - (navigationRef.current?.offsetHeight ?? 0)
    )
  }, [navigationRef])

  return (
    <div className='bg-gray-50' style={{ minHeight: dashboardMinimumHeight }}>
      <Navigation
        ref={navigationRef}
      />

      <main
        className='mx-auto max-w-7xl py-5 px-8'
        style={{ marginTop: navigationRef.current?.offsetHeight }}
      >
        <Outlet />
      </main>
    </div>
  )
}

export default React.memo(AppLayout)
