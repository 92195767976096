import * as OutlineIcons from '@heroicons/react/24/outline'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage: React.FC = () => {
  useDocumentTitle('Page not found')

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      <div className="grid text-center gap-2">
        <span className="text-4xl font-black text-indigo-700">404</span>

        <div className="grid gap-2">
          <span className="text-4xl font-black text-gray-800">
            Page not found
          </span>

          <span className="text-lg text-gray-500">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            The page that you're trying to find does <br />
            not exists or moved to other URL
          </span>
        </div>

        <div className="mt-8">
          <Link to="/c/" className="button gap-x-4">
            <span>
              <OutlineIcons.ArrowLeftIcon className="w-5 h-5" />
            </span>
            Go back to Home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NotFoundPage)
