import React from 'react'

const SplashPage: React.FC = () => {
  return (
    <div className="flex justify-center items-center fixed z-50 inset-0 w-full bg-white bg-opacity-40">
      <div>Loading...</div>
    </div>
  )
}

export default React.memo(SplashPage)
