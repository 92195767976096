import jwtDecode from 'jwt-decode'
import { AUTH_TOKEN, USER_INFO } from '../constants/storage'
import { IToken, ILoginResponse } from '../types/auth'
import { User } from '../types/user'

export const isTokenExpired = (token: string): boolean => {
  const { exp } = jwtDecode<IToken>(token)

  return Number(exp) <= Math.floor(new Date().getTime() / 1000)
}

export const storeToken = (data: ILoginResponse): void => {
  localStorage.setItem(AUTH_TOKEN, data.token)
}

export const storeUserInfo = (user: User): void => {
  localStorage.setItem(USER_INFO, JSON.stringify(user))
}

export const getToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN)
}

export const getUserInfo = (): User | null => {
  const userInfo = localStorage.getItem(USER_INFO)

  if (userInfo == null) { return userInfo }

  return JSON.parse(userInfo)
}

export const deleteToken = (): void => {
  localStorage.removeItem(AUTH_TOKEN)
}

export const deleteUserInfo = (): void => {
  localStorage.removeItem(USER_INFO)
}
