import App from 'App'
import { FlashProvider } from 'context/FlashContext'
import { InternetConnectionProvider } from 'context/InternetConnectivityContext'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

const Root: React.FC = () => {
  return (
    <FlashProvider>
      <InternetConnectionProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </InternetConnectionProvider>
    </FlashProvider>
  )
}

export default React.memo(Root)
