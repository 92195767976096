import { ORGANIZATION_ID } from 'constants/storage'

/**
 *
 * @param organizationId string
 */
export const storeOrganization = (organizationId: string): void => {
  localStorage.setItem(ORGANIZATION_ID, organizationId)
}

/**
 *
 * @returns string
 */
export const getOrganization = (): string | null => {
  return localStorage.getItem(ORGANIZATION_ID)
}

/**
 *
 * @returns null
 */
export const deleteOrganization = (): void => {
  localStorage.removeItem(ORGANIZATION_ID)
}
