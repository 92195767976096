export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'

export const LOADED = 'LOADED'

export const SET_APP_SCREEN_HEIGHT = 'SET_APP_SCREEN_HEIGHT'

export const LIST_ORGANIZATIONS = 'LIST_ORGANIZATIONS'
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION'

export const SET_MESSAGE = 'SET_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
