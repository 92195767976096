import React from 'react'
import * as uuid from 'uuid'
import { DELETE_MESSAGE, SET_MESSAGE } from 'constants/actions'
import { ContextProps } from 'types/context'
import { IDeleteMessagePayload, IFlashAction, IFlashContext, IFlashState, ISetMessagePayload, Message } from 'types/flash'

const initialState = {
  messages: []
}

const initialActions = {
  addMessage: () => {},
  getMessages: () => [],
  deleteMessage: () => {}
}

const FlashContext = React.createContext<IFlashContext>({
  ...initialState,
  ...initialActions
})

export const FlashProvider: React.FC<ContextProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer((state: IFlashState, action: IFlashAction) => {
    switch (action.type) {
      case SET_MESSAGE: {
        const { message } = action.payload as ISetMessagePayload

        return {
          ...state,
          messages: [
            ...state.messages,
            message
          ]
        }
      }

      case DELETE_MESSAGE: {
        const { id: messageId } = action.payload as IDeleteMessagePayload

        return {
          ...state,
          messages: state.messages
            .filter(({ id }) => id !== messageId)
        }
      }

      default:
        return state
    }
  }, initialState)

  const actions = React.useMemo(() => ({
    addMessage: (message: Omit<Message, 'id'>) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: {
            ...message,
            id: uuid.v4()
          }
        }
      })
    },
    getMessages: () => {
      return state.messages
    },
    deleteMessage: (id: string) => {
      dispatch({
        type: DELETE_MESSAGE,
        payload: { id }
      })
    }
  }), [state])

  return (
    <FlashContext.Provider value={{ ...state, ...actions }}>
      {children}
    </FlashContext.Provider>
  )
}

export const useFlash = (): IFlashContext => {
  const context = React.useContext(FlashContext)

  if (context === undefined) { throw new Error('useFlash may not be available outside of "FlashProvider"') }

  return context
}
