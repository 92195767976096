import AppLayout from 'components/layouts/AppLayout'
import { useAuth } from 'context/AuthContext'
import React, { Suspense } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import NotFoundPage from './NotFound'
import SplashPage from './Splash'

/* eslint-disable @typescript-eslint/promise-function-async */
const ProfilePage = React.lazy(() => import('./Profile'))
const OrganizationSelectionPage = React.lazy(
  () => import('./SelectOrganization')
)

const CatalogRouter = React.lazy(() => import('./catalog/Router'))
const CustomerRouter = React.lazy(() => import('./customer/Router'))
const OrderRouter = React.lazy(() => import('./order/Router'))
const OrganizationRouter = React.lazy(() => import('./organization/Router'))
const PurchaseRouter = React.lazy(() => import('./purchase/Router'))
const ReportRouter = React.lazy(() => import('./report/Router'))
const SellerRouter = React.lazy(() => import('./seller/Router'))
const SettingRouter = React.lazy(() => import('./settings/Router'))
const UserRouter = React.lazy(() => import('./user/Router'))
const VendorRouter = React.lazy(() => import('./vendor/Router'))
const WarehouseRouter = React.lazy(() => import('./warehouse/Router'))

const HomePage = React.lazy(() => import('./Home'))
const LogoutPage = React.lazy(() => import('./Logout'))

const Router: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate('/', { replace: true })
    }
  }, [isAuthenticated, isLoading, navigate])

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<SplashPage />}>
              <HomePage />
            </Suspense>
          }
        />

        <Route
          path="/logout"
          element={
            <Suspense fallback={<SplashPage />}>
              <LogoutPage />
            </Suspense>
          }
        />

        <Route
          path="/profile"
          element={
            <Suspense fallback={<SplashPage />}>
              <ProfilePage />
            </Suspense>
          }
        />

        <Route
          path="/choose-organization"
          element={
            <Suspense fallback={<SplashPage />}>
              <OrganizationSelectionPage />
            </Suspense>
          }
        />

        <Route
          path="/organization/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <OrganizationRouter />
            </Suspense>
          }
        />

        <Route
          path="/user/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <UserRouter />
            </Suspense>
          }
        />

        <Route
          path="/warehouse/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <WarehouseRouter />
            </Suspense>
          }
        />

        <Route
          path="/vendor/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <VendorRouter />
            </Suspense>
          }
        />

        <Route
          path="/seller/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <SellerRouter />
            </Suspense>
          }
        />

        <Route
          path="/catalog/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <CatalogRouter />
            </Suspense>
          }
        />

        <Route
          path="/order/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <OrderRouter />
            </Suspense>
          }
        />

        <Route
          path="/purchase/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <PurchaseRouter />
            </Suspense>
          }
        />

        <Route
          path="/report/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <ReportRouter />
            </Suspense>
          }
        />

        <Route
          path="/customer/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <CustomerRouter />
            </Suspense>
          }
        />

        <Route
          path="/settings/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <SettingRouter />
            </Suspense>
          }
        />

        <Route
          path="/*"
          element={
            <Suspense fallback={<SplashPage />}>
              <NotFoundPage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

export default React.memo(Router)
