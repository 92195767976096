import React from 'react'

const useDocumentTitle = (title: string): void => {
  React.useEffect(() => {
    if (!title) { throw new Error('Document title is required!') }

    document.title = `${title} | SellerBucket`
  }, [title])
}

export default useDocumentTitle
