import React from 'react'
import { Outlet } from 'react-router-dom'
import Logo from 'assets/images/logo.jpeg'

const AuthLayout: React.FC = () => {
  return (
    <div className='flex items-center justify-center h-screen w-full bg-gray-50'>
      <div className='w-full mx-3 md:mx-0 md:w-8/12 lg:w-5/12 xl:w-3/12'>
        <div className='grid gap-2'>
          <div className='flex justify-center'>
            <img
              src={Logo}
              alt='SellerBucket'
              className='h-14 w-14 rounded-full shadow md:h-20 md:w-20 2xl:w-24 2xl:h-24'
            />
          </div>

          <h2 className='text-center text-4xl font-extrabold text-gray-900'>
            Seller<span className='text-sellerbucket'>Bucket</span>
          </h2>
        </div>

        <main className='w-full mt-2 sm:mt-4 md:mt-6 lg:mt-8'>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default AuthLayout
