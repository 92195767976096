import { MENU_ITEMS, RIGHT_MENU_ITEMS } from 'constants/navigation'
import { NavigationProvider } from 'context/NavigationContext'
import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo'
import NavigationMenu from './NavigationMenu'

// eslint-disable-next-line react/display-name
const Navigation = React.forwardRef<HTMLElement, Record<string, unknown>>(
  (_, ref) => {
    return (
      <NavigationProvider>
        <header
          ref={ref}
          className="fixed top-0 left-0 right-0 bg-white shadow-sm z-30"
        >
          <div className="flex items-center gap-x-4 mx-auto py-4 px-8 w-full max-w-7xl">
            <div className="">
              <Link
                to="/c/"
                className="flex items-center gap-2 focus:outline-none"
              >
                <div className="h-8 w-8">
                  <Logo />
                </div>

                <div className="">
                  <span className="font-bold text-xl">
                    Seller<span className="text-indigo-600">Bucket</span>
                  </span>
                </div>
              </Link>
            </div>

            <nav className="flex-1 flex justify-between">
              <ul className="flex gap-x-2">
                {MENU_ITEMS.map((item) => (
                  <li className="" key={item.name}>
                    <NavigationMenu {...item} />
                  </li>
                ))}
              </ul>

              <ul className="flex">
                {RIGHT_MENU_ITEMS.map((item) => (
                  <li className="" key={item.name}>
                    <NavigationMenu {...item} />
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </header>
      </NavigationProvider>
    )
  }
)

export default React.memo(Navigation)
