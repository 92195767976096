import * as Sentry from '@sentry/react'
import axios from 'axios'
import * as SentryConfig from 'constants/sentry'
import { inProduction } from 'helpers/env'
import { configureClient } from 'helpers/http'
import 'index.css'
import ReactDOM from 'react-dom/client'
import reportWebVitals from 'reportWebVitals'
import Root from 'Root'

Sentry.init({
  dsn: SentryConfig.DSN,
  environment: SentryConfig.ENV,
  enabled: inProduction(),
  integrations: [new Sentry.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

configureClient(axios)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
