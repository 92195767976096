import axios from 'axios'
import { LOADED, LOGIN, LOGOUT } from 'constants/actions'
import { AUTHORIZATION, TOKEN_EXPIRED } from 'constants/http'
import {
  deleteToken,
  deleteUserInfo,
  getToken,
  getUserInfo,
  isTokenExpired
} from 'helpers/auth'
import React from 'react'
import {
  type IAuthAction,
  type IAuthContext,
  type IAuthState,
  type ILoginPayload,
  type ILoginResponse
} from 'types/auth'
import { type ContextProps } from 'types/context'
import { type User } from 'types/user'

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  authToken: null,
  user: {}
}

const initialActions = {
  login: async () => {},
  logout: async () => {},
  forgotPassword: async () => {},
  resetPassword: async () => {}
}

const AuthContext = React.createContext<IAuthContext>({
  ...initialState,
  ...initialActions
})

export const AuthProvider: React.FC<ContextProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (state: IAuthState, action: IAuthAction) => {
      switch (action.type) {
        case LOGIN: {
          const payload = action.payload as ILoginPayload

          return {
            ...state,
            isLoading: false,
            isAuthenticated: true,
            authToken: payload.authToken,
            user: payload.user
          }
        }

        case LOGOUT: {
          return {
            ...state,
            isLoading: false,
            authToken: null,
            isAuthenticated: false,
            user: {}
          }
        }

        case LOADED: {
          return {
            ...state,
            isLoading: false
          }
        }

        default:
          return state
      }
    },
    initialState
  )

  const actions = React.useMemo(
    () => ({
      login: async (response: ILoginResponse) => {
        dispatch({
          type: LOGIN,
          payload: {
            authToken: response.token,
            user: response.user
          }
        })
      },
      logout: async () => {
        deleteToken()
        deleteUserInfo()

        dispatch({ type: LOGOUT })
      },
      forgotPassword: async () => {},
      resetPassword: async () => {}
    }),
    []
  )

  React.useEffect(() => {
    let interceptors: number

    if (state.isAuthenticated) {
      interceptors = axios.interceptors.request.use((config) => {
        if (config.headers == null) {
          throw new Error('config.headers is null')
        }

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        config.headers[AUTHORIZATION] = `Bearer ${state.authToken}`

        return config
      })
    }

    return () => {
      if (interceptors) {
        axios.interceptors.request.eject(interceptors)
      }
    }
  }, [state])

  React.useEffect(() => {
    ;(async () => {
      const authToken = getToken()

      if (!state.isAuthenticated && authToken) {
        if (isTokenExpired(authToken)) {
          actions.logout()
        }

        const user = getUserInfo() as User

        dispatch({
          type: LOGIN,
          payload: {
            authToken,
            user
          }
        })
      } else {
        dispatch({
          type: LOADED
        })
      }
    })()
  }, [state.isAuthenticated, actions])

  React.useEffect(() => {
    const interceptors = axios.interceptors.response.use((response) => {
      if (response.status === TOKEN_EXPIRED) {
        actions.logout()
      }

      return response
    })

    return () => {
      axios.interceptors.response.eject(interceptors)
    }
  }, [actions])

  return (
    <AuthContext.Provider value={{ ...state, ...actions }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): IAuthContext => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth may not be available outside of "AuthProvider"')
  }

  return context
}
