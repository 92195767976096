import React from 'react'

interface LogoProps {
  color?: string
}

const Logo: React.FC<LogoProps> = ({ color }) => {
  return (
    <svg viewBox='0 0 1496.000000 1496.000000' preserveAspectRatio='xMidYMid meet'>
        <g transform='translate(0.000000,1496.000000) scale(0.100000,-0.100000)' fill={color} stroke='none'>
        <path d='M4410 14500 c-802 -7 -852 -9 -1137 -55 -740 -119 -1374 -429 -1851
        -904 -577 -574 -907 -1357 -971 -2301 -6 -80 -16 -1228 -22 -2575 -11 -2278
        -8 -4551 10 -7280 l6 -930 1105 -12 c3848 -40 6324 -38 8745 7 1166 22 1311
        30 1622 91 1285 251 2201 1208 2463 2574 42 219 61 415 70 715 12 376 12 6578
        0 7210 -9 498 -20 632 -70 875 -268 1304 -1213 2246 -2525 2515 -275 57 -352
        62 -1105 70 -807 9 -5180 9 -6340 0z m1983 -1847 l667 -6 0 -2452 0 -2452
        -392 -6 c-216 -4 -602 -12 -858 -18 -775 -18 -1458 10 -1765 72 -700 141
        -1273 593 -1600 1264 -130 266 -200 492 -246 795 -32 207 -32 554 -1 755 141
        910 747 1634 1601 1915 164 54 343 93 506 110 257 27 948 35 2088 23z m3787
        -3 c278 -11 595 -33 714 -51 725 -108 1389 -688 1699 -1484 208 -534 220
        -1130 35 -1686 -232 -702 -745 -1256 -1398 -1512 -395 -155 -817 -195 -1930
        -183 -491 5 -1446 22 -1457 25 -2 1 -3 1097 -3 2436 l0 2435 208 1 c114 1 441
        7 727 13 677 17 1078 18 1405 6z m-5730 -5440 c437 -20 710 -54 925 -116 1145
        -330 1894 -1541 1700 -2750 -156 -971 -852 -1735 -1804 -1980 -193 -50 -362
        -73 -621 -84 -255 -11 -1845 -13 -2197 -3 l-233 6 0 2454 0 2453 238 1 c130 0
        428 7 662 13 543 16 1056 19 1330 6z m5800 0 c342 -12 558 -47 815 -131 805
        -264 1406 -905 1615 -1724 153 -598 98 -1184 -160 -1704 -245 -492 -614 -856
        -1115 -1102 -331 -162 -631 -236 -1050 -261 -194 -12 -2468 -14 -2486 -2 -5 3
        -9 1002 -9 2464 l0 2460 223 3 c563 7 1947 5 2167 -3z'/>
        </g>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#4f46e5'
}

export default React.memo(Logo)
