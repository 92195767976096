import React from 'react'
import { type ContextProps } from 'types/context'
import { Status, type IInternetConnectionContext } from 'types/http'

const InternetConnectivityContext =
  React.createContext<IInternetConnectionContext>({
    status: Status.ONLINE,
    isOnline: true,
    isOffline: false
  })

export const InternetConnectionProvider: React.FC<ContextProps> = ({
  children
}) => {
  const [status, setStatus] = React.useState<Status>(Status.ONLINE)

  React.useEffect(() => {
    if (!navigator.onLine) {
      setStatus(Status.OFFLINE)
    }
  }, [])

  React.useEffect(() => {
    const onDeviceGoesOnline = (): void => {
      setStatus(Status.ONLINE)
    }

    const onDeviceGoesOffline = (): void => {
      setStatus(Status.OFFLINE)
    }

    window.addEventListener('online', onDeviceGoesOnline)
    window.addEventListener('offline', onDeviceGoesOffline)

    return () => {
      window.removeEventListener('online', onDeviceGoesOnline)
      window.removeEventListener('offline', onDeviceGoesOffline)
    }
  }, [])

  return (
    <InternetConnectivityContext.Provider
      value={{
        status,
        isOnline: status === Status.ONLINE,
        isOffline: status === Status.OFFLINE
      }}
    >
      {children}
    </InternetConnectivityContext.Provider>
  )
}

export const useInternetConnection = (): IInternetConnectionContext => {
  const context = React.useContext(InternetConnectivityContext)

  if (context === undefined) {
    throw new Error(
      'useInternetConnection may not be available outside of "InternetConnectivityContext"'
    )
  }

  return context
}
