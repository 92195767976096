export interface IResponse<T> {
  success: boolean
  data: T
  errors: Record<string, string[]>
}

export interface IErrorResponse extends IResponse<null> {}

export enum Status {
  ONLINE,
  OFFLINE
}

export interface IInternetConnectionContext {
  status: Status
  isOnline: boolean
  isOffline: boolean
}
