import { Axios } from 'axios'
import { URL, CLIENT_AGENT } from '../constants/api'
import { AUTHORIZATION, ORGANIZATION_ID_PARAM } from '../constants/http'
import { getToken } from './auth'
import { getOrganization } from './organization'

const token = getToken()
const organizationId = getOrganization()

export const configureClient = (client: Axios): Axios => {
  client.defaults.baseURL = URL
  client.defaults.headers.common['x-client'] = CLIENT_AGENT
  client.defaults.params = {}

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (token) {
    client.defaults.headers.common[AUTHORIZATION] = `Bearer ${token}`
  }

  if (organizationId) {
    client.defaults.params[ORGANIZATION_ID_PARAM] = organizationId
  }

  return client
}
