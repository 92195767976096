import { Menu } from '@headlessui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigation } from 'context/NavigationContext'

interface NavigationMenuItemProps {
  testid: string
  to?: string
  children: React.ReactElement | string | number | undefined
  isLink?: boolean
}

const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({ children, to, testid, isLink }) => {
  const { activeLink, setActiveLink } = useNavigation()
  const active = activeLink === testid

  if (isLink) {
    return (
      <Link
        to={to as string}
        className={`inline-flex font-medium text-sm -my-2 py-2 px-4 rounded focus:outline-none focus:bg-gray-100 ${active ? 'bg-gray-100' : ''}`}
        data-testid={testid}
        onClick={() => setActiveLink(testid)}
      >
        {children}
      </Link>
    )
  }

  return (
    <Menu.Button
      className={`inline-flex w-full justify-center rounded -my-2 py-2 px-4 text-sm font-medium focus:outline-none focus:bg-gray-100 ${active ? 'bg-gray-100' : ''}`}
      data-testid={testid}
      onClick={() => setActiveLink(testid)}
    >
      {children}
    </Menu.Button>
  )
}

NavigationMenuItem.defaultProps = {
  isLink: false
}

export default React.memo(NavigationMenuItem)
