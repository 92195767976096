import axios from 'axios'
import { getOrganization } from 'helpers/organization'
import React from 'react'
import { type ContextProps } from 'types/context'
import { type IOrganizationContext } from 'types/organization'

const OrganizationContext = React.createContext<IOrganizationContext>({
  isLoading: true,
  activeOrganization: undefined,
  selectOrganization: () => {}
})

export const OrganizationProvider: React.FC<ContextProps> = ({ children }) => {
  const [activeOrganization, selectOrganization] = React.useState<string>()
  const [isLoading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    const organizationId = getOrganization()

    if (organizationId) {
      selectOrganization(organizationId)
    }

    setLoading(false)
  }, [])

  React.useEffect(() => {
    let interceptors: number

    if (activeOrganization) {
      setLoading(false)

      interceptors = axios.interceptors.request.use((config) => {
        config.params.organizationId = activeOrganization

        return config
      })
    }

    return () => {
      if (interceptors) {
        axios.interceptors.request.eject(interceptors)
      }
    }
  }, [activeOrganization])

  return (
    <OrganizationContext.Provider
      value={{ activeOrganization, isLoading, selectOrganization }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}

export const useOrganization = (): IOrganizationContext => {
  const context = React.useContext(OrganizationContext)

  if (context === undefined) {
    throw new Error(
      'useOrganization may not be available outside of "OrganizationProvider"'
    )
  }

  return context
}
