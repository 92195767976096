import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from 'react-query'

import GlobalLayout from 'components/layouts/GlobalLayout'

import { AuthProvider } from 'context/AuthContext'
import { useFlash } from 'context/FlashContext'
import { OrganizationProvider } from 'context/OrganizationContext'
import AuthRouter from 'pages/auth/Router'
import AppRouter from 'pages/Router'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const { messages, deleteMessage } = useFlash()
  const onMessageSeen = React.useCallback(
    (id: string) => deleteMessage(id),
    [deleteMessage]
  )

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <OrganizationProvider>
          <Routes>
            <Route
              element={
                <GlobalLayout
                  flashMessages={messages}
                  onMessageSeen={onMessageSeen}
                />
              }
            >
              <Route path="/*" element={<AuthRouter />} />

              <Route path="/c/*" element={<AppRouter />} />
            </Route>
          </Routes>
        </OrganizationProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default React.memo(App)
