import React from 'react'
import { Outlet } from 'react-router-dom'
import { type Message } from 'types/flash'

interface GlobalLayoutProps {
  flashMessages: Message[]
  onMessageSeen: (id: string) => void
}

const GlobalLayout: React.FC<GlobalLayoutProps> = ({
  flashMessages,
  onMessageSeen
}) => {
  return (
    <div className="font-display antialiased font-normal text-gray-700 bg-white">
      <Outlet />

      {flashMessages.map((message) => {
        let color

        switch (message.type) {
          case 'success':
            color = 'green'
            break

          case 'error':
            color = 'red'
            break

          case 'warning':
            color = 'orange'
            break

          default:
            color = 'blue'
            break
        }

        return (
          <div
            className="grid gap-1 cursor-pointer fixed max-w-screen-sm bg-white top-0 right-0 z-50 rounded py-2.5 px-4 mr-10 mt-10 ring-1 ring-gray-200"
            key={message.id}
            onClick={() => {
              onMessageSeen(message.id)
            }}
          >
            {message.title && (
              <span className={`font-medium text-sm text-${color}-700`}>
                {message.title}
              </span>
            )}

            <span className={`text-sm text-${color}-500`}>{message.text}</span>
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(GlobalLayout)
