import AuthLayout from 'components/layouts/AuthLayout'
import { useAuth } from 'context/AuthContext'
import React, { Suspense } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import SplashPage from 'pages/Splash'
import NotFoundPage from '../NotFound'

/* eslint-disable @typescript-eslint/promise-function-async */
const ForgotPasswordPage = React.lazy(() => import('../auth/ForgotPassword'))
const LoginPage = React.lazy(() => import('../auth/Login'))
const RegistrationPage = React.lazy(() => import('../auth/Register'))
const ResetPasswordPage = React.lazy(() => import('../auth/ResetPassword'))
const VerificationPage = React.lazy(() => import('./Verify'))

const Router: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate('/c/', { replace: true })
    }
  }, [isLoading, isAuthenticated, navigate])

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<SplashPage />}>
              <LoginPage />
            </Suspense>
          }
        />

        <Route
          path="/register"
          element={
            <Suspense fallback={<SplashPage />}>
              <RegistrationPage />
            </Suspense>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <Suspense fallback={<SplashPage />}>
              <ForgotPasswordPage />
            </Suspense>
          }
        />

        <Route
          path="/reset-password/:token"
          element={
            <Suspense fallback={<SplashPage />}>
              <ResetPasswordPage />
            </Suspense>
          }
        />

        <Route
          path="/verify/:userId"
          element={
            <Suspense fallback={<SplashPage />}>
              <VerificationPage />
            </Suspense>
          }
        />

        <Route path="/*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default React.memo(Router)
