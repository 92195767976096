import { type MenuItem } from 'types/navigation'

export const MENU_ITEMS: MenuItem[] = [
  {
    label: 'Home',
    name: 'home',
    route: '/c/',
    testid: 'home-link'
  },
  {
    label: 'Catalogue',
    name: 'catalogue',
    testid: 'catalogue-menu-button',
    items: [
      [
        {
          label: 'Manage Catalog',
          name: 'manage-catalog',
          route: '/c/catalog',
          testid: 'manage-catalog-link'
        },
        {
          label: 'Add Product',
          name: 'add-product',
          route: '/c/catalog/add',
          testid: 'add-product-link'
        },
        {
          label: 'Manage Inventory',
          name: 'update-product',
          route: '/c/catalog/inventory',
          testid: 'manage-product-inventory-link'
        }
      ],
      [
        {
          label: 'Bulk Products Upload',
          name: 'bulk-product-upload',
          route: '/c/catalog/bulk-upload',
          testid: 'bulk-product-upload-link'
        },
        {
          label: 'Sync Products',
          name: 'sync-products',
          route: '/c/catalog/sync',
          testid: 'sync-products-link'
        }
      ]
    ]
  },
  {
    label: 'Orders',
    name: 'orders',
    testid: 'orders-menu-button',
    items: [
      [
        {
          label: 'Manage Orders',
          name: 'manage-orders',
          route: '/c/order',
          testid: 'manage-orders-link'
        },
        {
          label: 'Returned Orders',
          name: 'returned-orders',
          route: '/c/order/returned',
          testid: 'returned-orders-link'
        },
        {
          label: 'Enter Manual Orders',
          name: 'manual-orders',
          route: '/c/order/add',
          testid: 'manual-orders-link'
        }
      ],
      [
        {
          label: 'SLA Breached',
          name: 'sla-breached',
          route: '/c/order/sla-breached',
          testid: 'sla-breached-link'
        },
        {
          label: 'Cancelled Orders',
          name: 'cancelled-orders',
          route: '/c/order/cancelled',
          testid: 'cancelled-orders-link'
        }
      ],
      [
        {
          label: 'Manage Purchases',
          name: 'manage-purchases',
          route: '/c/purchase',
          testid: 'manage-purchases-link'
        },
        {
          label: 'Enter Purchase Order',
          name: 'add-purchase-order',
          route: '/c/purchase/add',
          testid: 'add-purchase-order-link'
        }
      ]
    ]
  },
  {
    label: 'Customers',
    name: 'customers',
    testid: 'customers-menu-button',
    items: [
      [
        {
          label: 'List Customers',
          name: 'customers',
          route: '/c/customer',
          testid: 'customers-link'
        },
        {
          label: 'Leaderboard',
          name: 'leaderboard',
          route: '/c/customer/leaderboard',
          testid: 'leaderboard-link'
        }
      ],
      [
        {
          label: 'Customers Engagement',
          name: 'engage',
          route: '/c/customer/engagement',
          testid: 'engage-link'
        },
        {
          label: 'Customer Review',
          name: 'customer-reviews',
          route: '/c/customer/reviews',
          testid: 'customer-reviews-link'
        }
      ]
    ]
  },
  {
    label: 'Reports',
    name: 'reports',
    testid: 'reports-menu-button',
    items: [
      [
        {
          label: 'Inventory Report',
          name: 'inventory-report',
          route: '/c/report/inventory',
          testid: 'inventory-report-link'
        },
        {
          label: 'Orders Report',
          name: 'orders-report',
          route: '/c/report/order',
          testid: 'orders-report-link'
        }
      ]
    ]
  }
]

export const RIGHT_MENU_ITEMS: MenuItem[] = [
  {
    label: 'Account',
    name: 'account',
    testid: 'account-menu-button',
    properties: {
      floatOn: 'right',
      subMenuWidthClass: 'w-56 2xl:w-64'
    },
    items: [
      [
        {
          label: 'Profile',
          name: 'profile',
          route: '/c/profile',
          testid: 'profile-link'
        },
        {
          label: 'Settings',
          name: 'settings',
          route: '/c/settings',
          testid: 'settings-link'
        }
      ],
      [
        {
          label: 'Users',
          name: 'manage-users',
          route: '/c/user',
          testid: 'manage-users-link'
        },
        {
          label: 'Warehouses',
          name: 'manage-warehouses',
          route: '/c/warehouse',
          testid: 'manage-warehouses-link'
        },
        {
          label: 'Vendors',
          name: 'manage-vendors',
          route: '/c/vendor',
          testid: 'manage-vendors-link'
        },
        {
          label: 'Sellers',
          name: 'manage-sellers',
          route: '/c/seller',
          testid: 'manage-sellers-link'
        },
        {
          label: 'Organizations',
          name: 'manage-organizations',
          route: '/c/organization',
          testid: 'manage-organizations-link'
        }
      ],
      [
        {
          label: 'Log out',
          name: 'logout',
          route: '/c/logout',
          testid: 'logout-link'
        }
      ]
    ]
  }
]
