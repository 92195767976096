import React from 'react'
import { useLocation } from 'react-router-dom'
import { MENU_ITEMS, RIGHT_MENU_ITEMS } from 'constants/navigation'
import { ContextProps } from 'types/context'
import { INavigationContext, SubMenuItem } from 'types/navigation'

const NavigationContext = React.createContext<INavigationContext>({
  activeLink: undefined,
  setActiveLink: () => {}
})

export const NavigationProvider: React.FC<ContextProps> = ({ children }) => {
  const location = useLocation()
  const [activeLink, setActiveLink] = React.useState<string>()

  React.useEffect(() => {
    if (!activeLink) {
      for (const menu of [...MENU_ITEMS, ...RIGHT_MENU_ITEMS]) {
        if (menu.route) {
          if (menu.route === location.pathname) {
            setActiveLink(menu.testid)
            break
          }

          continue
        }

        const routes = (menu.items as SubMenuItem[][])
          .reduce((item, prevItem) => [...item, ...prevItem], [])
          .map(({ route }) => route)

        if (routes.includes(location.pathname)) {
          setActiveLink(menu.testid)
          break
        }
      }
    }
  }, [activeLink, location])

  return (
    <NavigationContext.Provider value={{ setActiveLink, activeLink }}>
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigation = (): INavigationContext => {
  const context = React.useContext(NavigationContext)

  if (context === undefined) { throw new Error('useNavigation may not be available outside of "NavigationProvider"') }

  return context
}
